import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Card, Form } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import justicia from './img/justicia.png'
import asesoria from './img/asesoria.png'
import contabilidad from './img/contabilidad.png'
import impuestos from './img/impuestos.png'
import factura from './img/factura.png'
import sat from './img/sat.png'
import nomina from './img/nomina.png'
import marketing from './img/marketing.png'
import airplane from './img/airplane.png'
import hands from './img/hands.png'

import logosv from './img/logosv.png'
import line01 from './img/line01.png'
import line02 from './img/line02.png'
import line03 from './img/line03.png'
import title from './img/home_title.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'
import Services from './Services';

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Home() {
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const [ faceLink, setFaceLink ] = useState('https://www.facebook.com/sisesainc')

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LfTEKEqAAAAACaULpK0Fs6bUv6jdHGBsgZcydKB`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleOnChange = (event) => {
    var inputName = "set" + event.target.name.charAt(0).toUpperCase() + event.target.name.slice(1);
    eval(inputName)(event.target.value)
  }

  const handleShow = () => setShow(true);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LfTEKEqAAAAACaULpK0Fs6bUv6jdHGBsgZcydKB", { action: 'submit' }).then(token => {
        //if(isVerified){
        setLoading(true);
        //const recaptchaValue = recaptchaRef.current.getValue();
        var form = document.getElementById("contactForm");
        var formData = new FormData(form);
        formData.append('recaptcha_response', token);

        setLoading(true);
        axios.post("https://digital-eureka.com/sv_mailer.php", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then(function(response) {

            if(response.data == "FAIL" || response.data == "ERROR") {
              setLoading(false)
              setSuccess(false)
              handleShow()
            } else {
              setLoading(false)
              setSuccess(true)
              handleShow()

              setName("");
              setPhone("");
              setEmail("");
              setComments("");
            }

          }).catch(function(err) {
            setLoading(false)
            setSuccess(false)
            handleShow()
          });
      //} else 
        //alert("Por favor, verifica que no eres un robot")
      });
    });
  }

    return (
      <>
        <Modal show={showPrivacy} size="lg" onHide={ () => setShowPrivacy(false) }>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#000", paddingLeft: "34px" }}>Aviso de privacidad</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ color: "#000", textAlign: "justify" }}>
            <div className="policy-desc">
              De conformidad con lo establecido en el Artículo 17, fracción II de la Ley Federal de Protección de Datos Personales en posesión de los particulares le informamos que S&V Despacho Contable (el "Responsable"), con domicilio en Direccion del cliente y portal de internet https://misitio.com/ Tratará los datos personales que recabe de usted en su carácter de cliente y/o usuario, mismos que serán utilizados para identificarlo con las siguientes finalidades:
              Enviarle promociones e información generada por el "Responsable".<br/>
              Para fines estadísticos, publicitarios, promocionales, mercadológicos o de prospección comercial.<br/>
              Información sobre los servicios que el "Responsable" le proporciona.<br/>
              Enviarle facturas o alguna otra información derivada de la relación comercial.<br/>
              Atender y dar seguimiento a quejas.<br/>
              Llevar un récord de contrataciones por parte de su compañía de alguno de los servicios que ofrece el "Responsable".<br/>
              Por lo tanto y para alcanzar las finalidades antes expuestas, se tratarán los siguientes datos personales: nombre, teléfono y correo electrónico.
              <br/><br/>
              <b>Sistemas utilizamos para la captura de estos datos</b>
              <br/>
              1) Formularios de suscripción a contenidos: dentro de la web https://misitio.com/ existen varios formularios para activar la suscripción  con la finalidad de enviar campañas de marketing por correo electrónico, gestión de suscripciones y envío de boletines o noticias.
              2) Formulario de comentarios: La web incluye un formulario cuya finalidad es comentar los artículos y dar su opinión respetando la libertad de expresión. El usuario podrá publicar comentarios en los post que se publiquen. Los datos personales introducidos en el formulario para insertar estos comentarios serán utilizados exclusivamente para moderarlos y publicarlos,
              recabando igualmente estos datos que se almacenarán en los servidores de Godaddy.
              3) Formulario de contacto: Existe un formulario de contacto cuya finalidad es la respuesta de consultas, sugerencias o contacto profesional. En este caso se utilizará la dirección de correo electrónico para responder a las mismas y enviar la información que el usuario requiera a través de la web, estos datos se almacenarán en los servidores de Godaddy.
              4) Cookies: Cuando el usuario se registra o navega en esta web, se almacenan “cookies”, El usuario puede consultar en cualquier momento la política de cookies para ampliar información sobre el uso de cookies y como desactivarlas.
              6) Los usuarios podrán darse de baja en cualquier momento de los servicios prestados por https://misitio.com/ desde la misma Newsletter a través de un formulario de cancelación.
              7) Sistemas de rastreo utilizadas en este sitio: Google (Analytics), en https://misitio.com/ también se estudian las preferencias de sus usuarios, sus características demográficas, sus patrones de tráfico, y otra información en conjunto para comprender mejor quiénes constituyen su audiencia y qué es lo que esta necesita.
              <br/><br/>
              El rastreo de las preferencias de nuestros usuarios también nos ayuda a mostrarle los avisos publicitarios más relevantes.
              <br/><br/>
              Asimismo, informamos que para la captación de suscriptores y clientes utilizamos Facebook Ads teniendo instalado el correspondiente Pixel de Facebook, por lo que al generar un anuncio, se puede segmentar el público por el lugar, datos demográficos, intereses, etc por lo que los datos obtenidos por esta plataforma, estarían sujetos a esta política de privacidad desde el momento en que el usuario deja sus datos para unirse al boletín de https://misitio.com/
              <br/><br/>
              <b>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</b>
              Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del
              uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de
              que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases
              de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse
              al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos
              ARCO.
              <br/><br/>
              Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del
              siguiente medio:
              <br/>
              Enviando un correo electrónico a info@misitio.com

              El responsable no vende ni venderá datos personales de los usuarios a otras empresas ni terceros.
              El responsable compartirá con terceros los datos que posee, solo para cumplir con los servicios contratados por algún usuario, enviar la newsletter, cumplir con exigencias legales o para la administración del sitio web. A este efecto, se proporcionarán los debidos acuerdos de confidencialidad entre las partes.
              Los enlaces a terceros que se puedan encontrar en el sitio web poseen políticas de privacidad ajenas a el responsable. El acceso a estos sitios deberá ser responsabilidad del usuario, siendo su responsabilidad conocerlas y su decisión aceptarlas o no.
              Los datos de contacto de la persona o departamento de datos personales, que está cargo de dar trámite a las solicitudes de derechos ARCO (GDPR), son los siguientes:
              a) Nombre de la persona o departamento de datos personales: Juan Perez b) Domicilio: Direccion cliente
              c) Correo electrónico: info@misitio.com
              <br/><br/>
              ¿Cómo puede limitar el uso o divulgación de su información personal?
              Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios: Enviar un correo a info@misitio.com
              <br/><br/>
              El uso de tecnologías de rastreo en nuestro portal de internet. Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, asÌ como brindarle un mejor servicio y experiencia al navegar en nuestra página.
              Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines: Ofrecer servicios y productos relevantes para el usuario. Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: Identificadores, nombre de usuario y contraseñas de una sesión, Idioma preferido por el usuario, región en la que se encuentra el usuario. Tipo de navegador del usuario, Tipo de sistema operativo del usuario, Fecha y hora del inicio y final de una sesión de un usuario, Páginas web visitadas por un usuario, Búsquedas realizadas por un usuario, Publicidad revisada por un usuario, Listas y hábitos de consumo en páginas de compras.
              <br/><br/>
              En relación con la gestión de tus datos asociados a los perfiles sociales de El Responsable, el ejercicio del derecho de acceso, dependerá de la funcionalidad de la red social y las posibilidades de acceso a la información de los perfiles de los usuarios. Con relación a los derechos de acceso y rectificación, le recomendamos que sólo podrá satisfacerse en relación a aquella información que se encuentre bajo el control de El Responsable Además podrá dejar de interactuar, seguir o recibir información de los perfiles sociales de El Responsable, eliminar los contenidos que dejen de interesarle o restringir con quien comparte sus conexiones, mediante los mecanismos estipulados en las diferentes redes sociales.
              <br/><br/>
              <b>¿Cómo puede conocer los cambios en este aviso de privacidad?</b>
              El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
              <br/><br/>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: correo electrónico a info@misitio.com.
              <br/><br/>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente: se actualizará la sección de aviso de privacidad en la página web.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={ () => setShowPrivacy(false) }>
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
        
        <Modal show={show} onHide={ () => setShow(false) }>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className={success ? '' : 'hide'}>Mensaje Enviado</div>
              <div className={success == false ? '' : 'hide'}>Error</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={success ? '' : 'hide'} align="center">
              Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.
            </div>
            <div className={success == false ? '' : 'hide'} align="center">Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={ () => setShow(false) }>
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="banner-bg" align="center">
          <Container>
            {/* <Row>
              <Col md={ 6 }>
                <div className="banner-container" align="center">
                  <div className="banner-text-container">
                    <div className='banner-title'>¿No sabes qué onda con tus números?</div><br/>
                    <div className='banner-desc'>Realiza una <span className='banner-desc-gold'>asesoría gratuita</span> para conocer tu vida contable.</div><br/>
                    <div className='banner-desc' style={{ fontWeight: 700 }}>¡Agenda una cita personal con nuestros contadores!</div><br/>

                    <div>
                      <Form id="contactForm" onSubmit={handleOnSubmit}>
                        <Container>
                          <Row className="align-items-center">
                            <Col>
                              <div className="col-md-12 col-sm-12 col-xs-12" style={{textAlign: "left"}}>
                                <Form.Group controlId="formGridEmail" className='mb-3'>
                                  <Form.Control required name="name" value={name} placeholder="Nombre:" onChange={handleOnChange}/>
                                </Form.Group>

                                <Form.Group controlId="formGridEmail" className='mb-3'>
                                  <Form.Control required type="number" value={phone} name="phone" placeholder="Teléfono:" onChange={handleOnChange}/>
                                </Form.Group>

                                <Form.Group controlId="formGridEmail" className='mb-3'>
                                  <Form.Control required type="email" value={email} name="email" placeholder="Email:" onChange={handleOnChange}/>
                                </Form.Group>

                                <Form.Group controlId="formGridEmail" className='mb-3'>
                                  <Form.Control required name="comments" as="textarea" value={comments} rows="4" placeholder="Comentarios:" onChange={handleOnChange}/>
                                </Form.Group>
                                <br/>

                                <div align="right">
                                  <Button disabled={isLoading} type="submit" className='btn-submit'>{isLoading ? 'Enviando…' : 'Enviar'}</Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col md={ 9 }>
                <div className='section-home'> <img src={ title } className='full-container' /> </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="banner-bg-body" align="center">
          <br/><br/>
          <div className='section'>
            <Container>
              <Row>
                <Col>
                  <div className='banner-subtitle'> Somos Servicios y Valores </div> <br/><br/>
                  <div className='banner-desc'> Somos una firma con 30 años de trayectoria pudiendo brindar y sumar esfuerzos entre el cliente y nosotros, exponenciando los servicios y valores a través del diseño de acciones integrales en el ámbito contable fiscal, redoblando soluciones en los ámbitos administrativos, financieros y legales de cada uno de nuestros clientes.  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className='section'>
            <div className='banner-title-big'> Un servicio <b>diferente</b>  </div>
            <br/><br/><br/>
          
            <Container>
              <Row>
                <Col md={ 4 } xs={ 12 }>
                  <div className='banner-title-small'> Misión </div><br/>
                  <div className='banner-desc-small'> Establecer un vínculo con cada uno de nuestros clientes, para satisfacer sus necesidades con base a servicios de calidad y de excelencia siempre anteponiendo el profesionalismo y veracidad en los resutados.  </div>
                </Col>
                <Col md={ 4 }></Col>
                <Col md={ 4 }></Col>
              </Row>
              <Row>
                <Col md={ 4 } className='col-mobile-hide'>
                  <div align="right">
                    <img src={ line01 } className='img-50'/>
                  </div>
                </Col>
                <Col md={ 4 } className='col-mobile-hide'>
                  <div align="center">
                    <img src={ logosv } className='img-80'/>
                  </div>
                </Col>
                <Col md={ 4 }>
                  <br/>
                  <div className='banner-title-small'> Visión </div><br/>
                  <div className='banner-desc-small'> Ser una firma reconocida por su profesionalismo, honestidad responsabilidad y formalidad en el área fiscal, contable, administrativa y de servicios.  </div>
                  <div align="left" className='col-mobile-hide'>
                    <img src={ line02 } className='img-50'/>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={ 4 }></Col>
                <Col md={ 4 } className='col-mobile-hide'>
                  <div align="center" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <img src={ line03 } className='img-30'/>
                  </div>
                </Col>
                <Col md={ 4 }></Col>
              </Row>
              <br/>
              <Row>
                <Col md={ 4 }></Col>
                <Col md={ 4 }>
                  <div className='banner-title-small'> Objetivos </div><br/>
                  <div className='banner-desc-small epoligue-font capital-brown'> <span className='capital-style'>S</span>er un valor agregado a cada compañia y no ser un costo interminable.  </div>
                  <br/><br/>
                  <div className='banner-desc-small epoligue-font capital-brown'> <span className='capital-style capital-gold'>V</span>alorar la realidad del cliente para convertirla en estrategias alcanzables.  </div>
                  <br/><br/>
                  <div className='banner-desc-small epoligue-font capital-brown'> <span className='capital-style'>S</span>er una línea en el tiempo con veracidad y resultados.  </div>
                  <br/><br/>
                  <div className='banner-desc-small epoligue-font capital-brown'> <span className='capital-style capital-gold'>V</span>eracidad y transparecncia en las soluciones y en los valores de ética y profesionalismo.  </div>
                  <br/>
                </Col>
                <Col md={ 4 }></Col>
              </Row>
            </Container>
          </div>

          <div className='section'>
            <div className='banner-title-big'> <span className='capital-gold'>“</span>  Business are Business  <span className='capital-gold'>”</span>   </div>
          </div>

          <div className='section'>
            <Container>
              <Row>
                <Col>
                  <div className='banner-subtitle'> Nuestros Valores </div> <br/><br/>
                  <div className='banner-desc'> Brindar la <b>Ética</b> de nuestros servicios por el bienestar del cliente; siempre con la <b>Honestidad</b> en los resultados y las estrategias que en conjunto con las necesidades de su empresa se pueda trazar la <b>Objetividad</b> con los resultados de <b>Calidad</b> y bienestar por una relación bilateral, logrando en conjunto la <b>Confiabilidad</b> de las soluciones y valores aportados. </div>
                </Col>
              </Row>
            </Container>
          </div>

          <Services />

          <div className='section'>
            <Container>
              <Row>
                <Col md={ 5 }>
                  <div> <img src={ hands } className='img-80'/> </div>
                </Col>
                <Col md={ 7 }>
                  <div className='banner-subtitle' align="left"> Somos Agentes Capacitores Externos </div> <br/><br/>
                  <div className='banner-desc-small' align="left"> 
                    <div>Estamos conscientes que en el mundo laboral existe mucha competencia y que cada vez tenemos que estar mejor preparados para lograr sobresalir. </div><br/>

                    <div>Es por eso por lo que <b>S&V Business are Business</b> está comprometido a compartir y apoyar mediante <b>Cursos Autorizados</b> por la Secretaría del Trabajo y Previsión Social como <b>Agente Capacitor Externo</b> bajo el número de folio <b>SAV180222PJ7-0013</b>. </div><br/>

                    <div><b> Nuestros cursos </b></div><br/>

                    <ul>
                      <li style={{ fontWeight: 400 }}>Aspectos contables y fiscales que deben controlar los empresarios.</li>  
                      <li style={{ fontWeight: 400 }}>Estrategias fiscales para empresarios.</li>
                    </ul><br/>

                    <div align="right"> <Button type="submit" className='btn-submit-brown' onClick={ () => window.location.href = '/cursos' } > Conoce más... </Button> </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          
          <div className="section" align="center">
            <div className="banner-text-container">
              <div className='banner-title'>Agenda una asesoría personal <span className='banner-desc-gold'>gratuita</span> con nuestros contadores </div><br/>
              <div className='banner-desc banner-desc-gold' style={{ fontWeight: 700 }}>¡Agenda una cita personal con nuestros contadores!</div><br/>
              {/*<div className='banner-desc'>Contáctanos para agendar una <span className='banner-desc-gold'>asesoría gratuita</span> personal con alguno de nuestros contadores</div>*/}

              <br/>
              <div>
                <Form id="contactForm" onSubmit={handleOnSubmit}>
                  <Container className='img-50'>
                    <Row className="align-items-center">
                      <Col>
                        <div className="col-md-12 col-sm-12 col-xs-12" style={{textAlign: "left"}}>
                          <Form.Group controlId="formGridEmail" className='mb-3'>
                            <Form.Control required name="name" value={name} placeholder="Nombre:" onChange={handleOnChange}/>
                          </Form.Group>

                          <Form.Group controlId="formGridEmail" className='mb-3'>
                            <Form.Control required type="number" value={phone} name="phone" placeholder="Teléfono:" onChange={handleOnChange}/>
                          </Form.Group>

                          <Form.Group controlId="formGridEmail" className='mb-3'>
                            <Form.Control required type="email" value={email} name="email" placeholder="Email:" onChange={handleOnChange}/>
                          </Form.Group>

                          <Form.Group controlId="formGridEmail" className='mb-3'>
                            <Form.Control required name="comments" as="textarea" value={comments} rows="4" placeholder="Asunto:" onChange={handleOnChange}/>
                          </Form.Group>
                          <br/>

                          <div align="right">
                            <Button disabled={isLoading} type="submit" className='btn-submit-brown'>{isLoading ? 'Enviando…' : 'Enviar'}</Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </div>
            </div>
          </div>

        </div>
      </>
        
    );
}
export default Home;


