import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'
import { Facebook, Whatsapp, TelephoneFill, Instagram, ArrowLeft } from 'react-bootstrap-icons';

function Contact() {
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LfTEKEqAAAAACaULpK0Fs6bUv6jdHGBsgZcydKB`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleOnChange = (event) => {
    var inputName = "set" + event.target.name.charAt(0).toUpperCase() + event.target.name.slice(1);
    eval(inputName)(event.target.value)
  }

  const handleShow = () => setShow(true);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LfTEKEqAAAAACaULpK0Fs6bUv6jdHGBsgZcydKB", { action: 'submit' }).then(token => {
        //if(isVerified){
        setLoading(true);
        //const recaptchaValue = recaptchaRef.current.getValue();
        var form = document.getElementById("contactForm");
        var formData = new FormData(form);
        formData.append('recaptcha_response', token);

        setLoading(true);
        axios.post("https://digital-eureka.com/sv_mailer.php", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then(function(response) {

            if(response.data == "FAIL" || response.data == "ERROR") {
              setLoading(false)
              setSuccess(false)
              handleShow()
            } else {
              setLoading(false)
              setSuccess(true)
              handleShow()

              setName("");
              setPhone("");
              setEmail("");
              setComments("");
            }

          }).catch(function(err) {
            setLoading(false)
            setSuccess(false)
            handleShow()
          });
      //} else 
        //alert("Por favor, verifica que no eres un robot")
      });
    });
  }

  return (
    <>
      <div className="banner-bg-contacto" align="center">
        <Container>
          <br/>
          <br/>
          <br/>
          <br/>
          <div align="left" className='nav-back' onClick={ () => window.location.href = '/' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
        </Container>
        <div className="section" align="center">
          <Container>
            <Row>
              <Col md={ 9 }>
                <div className="banner-text-container">
                  <div className='banner-title'>Agenda una asesoría personal <span className='banner-desc-gold'>gratuita</span> con nuestros contadores </div><br/>
                    <div className='banner-desc banner-desc-gold' style={{ fontWeight: 700 }}>¡Agenda una cita personal con nuestros contadores!</div><br/>

                  <div>
                    <Form id="contactForm" onSubmit={handleOnSubmit}>
                      <Container className='img-50'>
                        <Row className="align-items-center">
                          <Col>
                            <div className="col-md-12 col-sm-12 col-xs-12" style={{textAlign: "left"}}>
                              <Form.Group controlId="formGridEmail" className='mb-3'>
                                <Form.Control required name="name" value={name} placeholder="Nombre:" onChange={handleOnChange}/>
                              </Form.Group>

                              <Form.Group controlId="formGridEmail" className='mb-3'>
                                <Form.Control required type="number" value={phone} name="phone" placeholder="Teléfono:" onChange={handleOnChange}/>
                              </Form.Group>

                              <Form.Group controlId="formGridEmail" className='mb-3'>
                                <Form.Control required type="email" value={email} name="email" placeholder="Email:" onChange={handleOnChange}/>
                              </Form.Group>

                              <Form.Group controlId="formGridEmail" className='mb-3'>
                                <Form.Control required name="comments" as="textarea" value={comments} rows="4" placeholder="Asunto:" onChange={handleOnChange}/>
                              </Form.Group>
                              <br/>

                              <div align="right">
                                <Button disabled={isLoading} type="submit" className='btn-submit-brown'>{isLoading ? 'Enviando…' : 'Enviar'}</Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
      
  );
}
export default Contact;


